:root {
  --app-height: 100%;
}

body {
  height: var(--app-height);
  margin: 0;
}

#modal {
  /* need to make bg color white for a.com */
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

#modal:empty {
  display: none;
}

.spinner-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.h-xs-100 {
  height: 100%;
}

@media (min-width: 768px) {
  .h-xs-100 {
    height: auto;
  }
}
