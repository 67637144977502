@import '../node_modules/bootstrap/scss/functions';

@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';

// apply bootstrap styles only within our app
.rcc-bootstrap {
  @import '../node_modules/bootstrap/scss/bootstrap';
}
