/* mShop styles */
div#root.root-amz.root-amz-mshop {
  height: calc(100% - 60px) !important;
}
div.a-popover.rcc-mshop-modal-parent #modal {
  margin-top: 0 !important;
  height: calc(100% - 90px) !important;
}
div#root.root-amz.root-amz-mshop-android {
  height: calc(100% + 46px) !important;
}
div.a-popover.rcc-mshop-modal-parent-android #modal {
  margin-top: 0 !important;
  height: 100% !important;
}
.root-mshop-hidden {
  visibility: hidden !important;
}
.rcc-body-no-overflow {
  overflow: hidden !important;
}

/* a.com browser styles */
div#root.root-amz {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 0;
}
.a-popover.a-popover-modal div#root.root-amz {
  height: calc(100vh - 250px) !important;
  max-height: 650px;
}

div.a-popover-modal .a-popover-wrapper {
  border: 0 !important;
}

/* make main modal content 100% dimensions width and height for mobile in a.com */
div.a-popover .rcc-mobile-content-parent {
  padding: 0 !important;
  height: calc(100dvh - 50px) !important;
}
/* make main modal content 100% dimensions width and height for mShop */
div.a-popover .rcc-mobile-content-parent-mshop {
  padding: 0 !important;
  height: calc(100vh - 50px) !important;
}

/* modal within modal for a.com with margin-top */
div.a-popover #modal {
  margin-top: 50px;
}
@media (min-width: 768px) {
  div.a-popover #modal {
    margin-top: 64px;
  }
}

/* Styles for debugging a.com version in localhost */
.localhost-amz-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  width: 80%;
  max-width: 650px;
}

/* Prevent scrolling on input focus for mobile iOS */
@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .root-amz input[type='text']:focus,
  .root-amz input[type='number']:focus {
    animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
  }
}
